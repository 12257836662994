import 'uuid';
window.rangy = require('./assets/javascripts/textAngular/dist/rangy-core.js');
window.rangy.saveSelection = require('./assets/javascripts/textAngular/dist/rangy-selectionsaverestore.js');
require('./assets/javascripts/textAngular/dist/textAngular-rangy.min.js');
require('./assets/javascripts/textAngular/dist/textAngular-sanitize.min.js');
window.taTools = {};
require('./assets/javascripts/textAngular/dist/textAngularSetup.js');
require('./assets/javascripts/textAngular/dist/textAngular.js');
require('./assets/javascripts/angular-validator/dist/angular-validator.min.js');
require('./assets/javascripts/angular-validator/dist/angular-validator-rules.min.js');
require('restangular/dist/restangular.min');